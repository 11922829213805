<template>
  <div class="container">
    <div class="header">
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="图纸名称">
          <el-input v-model="drawingName" placeholder="请输入图纸名称" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="initTable">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="success" size="small" @click="open('add')" icon="el-icon-plus" v-if="isAdmin">添加</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="content">
      <el-table :data="layoutList" stripe style="width: 100%" height="100%" v-loading="loading">
        <el-table-column prop="name" label="图纸名称" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="drawTypeName" label="图纸类型" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="buildingName" label="所属建筑" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="buildingType" label="建筑类别" show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.buildingType===0?'本单位管理建筑':'本单位使用建筑'}}
          </template>
        </el-table-column>
        <el-table-column prop="describes" label="描述" show-overflow-tooltip>
        </el-table-column>
        <el-table-column label="操作" width="140">
          <template slot-scope="scope">
            <el-tooltip class="item" effect="dark" content="修改" placement="top" v-if="isAdmin">
              <el-button size="mini" @click="open('edit',scope.row)" type="warning" icon="el-icon-edit" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top" v-if="isAdmin">
              <el-button type="danger" @click="delLayout(scope.row.id)" size="mini" icon="el-icon-delete-solid" circle></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="查看附件" placement="top">
              <el-button type="primary" @click="open('check',scope.row)" size="mini" icon="el-icon-view" circle></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="footer">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <!-- 表单 -->
    <el-dialog :title="optFlag?'添加布局':'修改布局'" :visible.sync="layoutShow" width="40%" @close="clear" v-dialogDrag :close-on-click-modal="false">
      <el-form :model="layout" ref="layoutForm" :rules="rules" label-width="120px">
        <el-form-item label="图纸建筑类别" prop="buildingType">
          <el-radio-group v-model="layout.buildingType" size="small">
            <el-radio v-for="(item,i) in [{id:0,text:'本单位管理建筑'},{id:1,text:'本单位使用建筑'}]" :key="i" :label="item.id">
              {{item.text}}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="所属建筑" prop="buildingId">
          <el-select v-model="layout.buildingId" placeholder="请选择所属建筑" clearable size="small" style="float:left">
            <el-option v-for="(item,i) in buildingList" :key="i" :label="item.buildingName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图纸类型" prop="drawType">
          <el-select v-model="layout.drawType" placeholder="请选择图纸类型" clearable size="small" style="float:left">
            <el-option v-for="(item,i) in drawingTypeList" :key="i" :label="item.text" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图纸名称" prop="name">
          <el-input v-model="layout.name" placeholder="请输入图纸名称" size="small" clearable></el-input>
        </el-form-item>
        <el-form-item label="描述">
          <el-input v-model="layout.describes" type="textarea" placeholder="请输入图纸名称"></el-input>
        </el-form-item>
        <el-form-item label="上传图片">
          <el-upload class="upload-demo" ref="upload" drag action="http://jaxf.jjxsw.vip:9002/app-version/uploadFile" :limit="1" :headers="{Authorization:token}" :on-success="uploadSuccess" :on-error="uploadError">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text"><em>点击上传</em></div>
            <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过2M</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span>
        <el-button @click="layoutShow = false">取 消</el-button>
        <el-button type="primary" @click="addLayout" v-show="optFlag">添 加</el-button>
        <el-button type="primary" @click="updateLayout" v-show="!optFlag">修 改</el-button>
      </span>
    </el-dialog>
    <!-- 附件 -->
    <el-dialog title="附件" :visible.sync="enclosureShow" width="30%">
      <el-image :src="enclosure" fit="fill" :lazy="true" :preview-src-list="[enclosure]"></el-image>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    companyId: {
      type: Number,
      default: 0
    },
    isAdmin: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      currentPage: 1,
      total: 0,
      drawingName: '',
      token: '',
      layoutList: [],
      buildingList: [],
      drawingTypeList: [],
      layoutId: '',
      layout: {
        buildingId: '',
        buildingType: '',
        describes: '',
        drawType: '',
        imageUrl: '',
        name: ''
      },
      layoutCopy: {},
      enclosure: '',
      layoutShow: false,
      enclosureShow: false,
      optFlag: true,
      loading: false,
      rules: {
        buildingType: [
          { required: true, message: '请选择建筑类别', trigger: 'change' }
        ],
        buildingId: [
          { required: true, message: '请选择所属建筑物', trigger: 'change' }
        ],
        drawType: [
          { required: true, message: '请选择图纸类型', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请输入图纸名称', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.initTable()
    this.getBuilding()
    this.getDrawingType()
    this.layoutCopy = JSON.stringify(this.layout)
    this.token = sessionStorage.getItem('token')
  },
  methods: {
    handleCurrentChange (val) {
      this.currentPage = val
      this.initTable()
    },
    async initTable () {
      const data = {}
      if (this.drawingName) {
        data.name = this.drawingName
      }
      if (this.companyId !== 0) {
        data.unitId = this.companyId
      }
      data.pageNo = this.currentPage
      data.pageSize = 10
      this.loading = true
      const { data: result } = await this.$axios.get('/layoutDrawing/list', { params: data })
      if (result.code === 200) {
        this.layoutList = result.data.result
        this.total = result.data.total
        this.loading = false
      } else {
        this.loading = false
        this.$message.error(result.msg)
      }
    },
    async addLayout () {
      this.$refs.layoutForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.post('/layoutDrawing/add', this.layout)
          if (result.code === 200) {
            this.$message.success('添加成功')
            this.layoutShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    async updateLayout () {
      this.$refs.layoutForm.validate(async valid => {
        if (valid) {
          const { data: result } = await this.$axios.put(`/layoutDrawing/edit/${this.layoutId}`, this.layout)
          if (result.code === 200) {
            this.$message.success('添加成功')
            this.layoutShow = false
            this.initTable()
          } else {
            this.$message.error(result.msg)
          }
        }
      })
    },
    // 删除某条数据
    async delLayout (id) {
      const confirm = await this.$confirm('此操作将永久删除该布局, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).catch(err => err)
      if (confirm === 'confirm') {
        const { data: result } = await this.$axios.delete(`/layoutDrawing/del/${id}`)
        if (result.code === 200) {
          this.$message.success('删除成功')
          this.initTable()
        } else {
          this.$message.error(result.msg)
        }
      }
    },
    open (flag, row) {
      switch (flag) {
        case 'add':
          this.optFlag = true
          this.layoutShow = true
          this.$nextTick(() => {
            this.$refs.layoutForm.clearValidate()
            this.$refs.upload.clearFiles()
          })
          break
        case 'edit':
          this.optFlag = false
          this.layout = JSON.parse(JSON.stringify(row))
          this.layoutShow = true
          this.layoutId = row.id
          this.$nextTick(() => {
            this.$refs.layoutForm.clearValidate()
            this.$refs.upload.clearFiles()
          })
          break
        case 'check':
          if (!row.imageUrl) {
            this.$message('该条数据暂无附件')
          } else {
            this.enclosure = row.imageUrl
            this.enclosureShow = true
          }
          break
      }
    },
    async getBuilding () {
      const { data: result } = await this.$axios.get('/building/listAllBuilding')
      if (result.code === 200) {
        this.buildingList = result.data
      }
    },
    async getDrawingType () {
      const { data: result } = await this.$axios.get('/dict/list', { params: { dictkey: 'drawingType' } })
      if (result.code === 200) {
        this.drawingTypeList = result.data
      }
    },
    uploadSuccess (res) {
      if (res.code === 200) {
        this.layout.imageUrl = res.data
        this.$message.success('上传成功')
      }
    },
    uploadError (err) {
      if (err) {
        this.$message.error('上传失败')
      }
    },
    clear () {
      this.layout = JSON.parse(this.layoutCopy)
      this.$refs.layoutForm.clearValidate()
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  box-shadow: none !important;
}
.container:after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  border: none !important;
}
</style>
